import { lazy, Suspense } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"


const componentLoading = <div>Loading...</div>
const Home = lazy(() => import('./components/Home/Home.js'))
const Resume = lazy(() => import('./components/Resume/Resume.js'))
// const Blog = lazy(() => import('./components/Blog/Blog.js'))
// const BlogDetail = lazy(() => import('./components/Blog/components/Detail.js'))


function Routes() {
  return (
    <Suspense fallback={componentLoading}>
      <Router>
        <Switch>
          <Route exact path="/"><Home /></Route>
          <Route exact path="/resume"><Resume /></Route>
          {/* <Route exact path="/blog"><Blog /></Route> */}
          {/* <Route path={`/blog/:title`}><BlogDetail /></Route> */}
          <Route exact path="*"><Home /></Route>
        </Switch>
      </Router>
    </Suspense>
  );
}


export default Routes